import React from 'react'
import { StaticQuery, graphql } from "gatsby";

import styled from '@emotion/styled'

import '../styles/main.scss'

const SiteContainer = styled.div`
  ${tw`py-10 px-2`}
`;

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            copyrightInfo
            author
            dishonestPhrase
          }
        }
      }
    `}
    render={data => {
      const metadata = data.site.siteMetadata;
      return (
        <SiteContainer className="o-container">
          {children}
        </SiteContainer>
    )}}/>
)