import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layouts/base-layout'
import { graphql } from 'gatsby';

export default ({ data }) => {
	const indexPage = data.allMarkdownRemark.edges[0].node;
	return (
		<div>
			<Helmet
				title="GSoC | Miroslav Foltýn"
				meta={[
					{ name: 'description', content: 'GSoC | Miroslav Foltýn' },
					{ name: 'robots', content: 'noindex, nofollow' },
				]}
				/>
			<Layout>
				<h1>{indexPage.frontmatter.title}</h1>
				<div dangerouslySetInnerHTML={{ __html: indexPage.html }}></div>
			</Layout>
		</div>
	);
};

export const query = graphql`
{
  allMarkdownRemark(filter: { frontmatter: { path: { eq: "/" }}}) {
    edges {
			node {
        id
        frontmatter {
          title
          path
        }
        html
    	}
    }
  }
}
`;
